<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- user list -->
            <section class="user-list total-candidates section-gap-30">
                <a href="javascript:void(0)" @click="toggleCandidateFilter()" class="filter__toggler"><i class="eicon e-menu"></i></a>
                <form action="" @submit.prevent="" class="form-filter candidates-filter flex-wrap">
                    <div class="d-flex flex-wrap filter__options" :class="{show: showCandidateFilter}">
                        <div class="form-group">
                            <div class="search-bar">
                                <input type="text" ref="candidate_name" v-model="filter.candidateName"
                                       @keyup="filterCandidates" :placeholder="$t('search candidates name')" autofocus/>
                            </div>
                        </div>
                        <div class="form-group">
                            <multiselect
                                    @input="filterCandidates"
                                    @select="jobSelected"
                                    id="jobId"
                                    v-model="filter.job"
                                    :options="jobs"
                                    :placeholder="$t('Select Job')"
                                    selectLabel=""
                                    deselectLabel=""
                                    label="title"
                                    track-by="id"
                            ></multiselect>
                        </div>
                        <div class="form-group">
                            <multiselect
                                    @input="filterCandidates"
                                    id="rating"
                                    v-model="filter.rating"
                                    :options="ratings"
                                    :placeholder="$t('Select Rating')"
                                    selectLabel=""
                                    deselectLabel=""
                                    label="id"
                                    track-by="id"
                            ></multiselect>
                        </div>

                        <div class="form-group">
                            <multiselect
                                    @input="filterCandidates"
                                    id="status"
                                    :multiple=false
                                    v-model="filter.status"
                                    :options="jobApplyStatus"
                                    :placeholder="$t('Select status')"
                                    selectLabel=""
                                    deselectLabel=""
                                    label="title"
                                    track-by="id"
                            ></multiselect>
                        </div>

                        <button type="button" @click="resetFilter()" class="button semi-button-warning">{{
                                $t("reset")
                            }}
                        </button>
                    </div>
                </form>
                <div class="recent-applicants-sections">
                    <template v-if="isContentLoading">
                        <div class="row row-cols-xl-5 has-col-gap">
                            <div class="col" v-for="index in 10" :key="index">
                                <CandidateCardLoader></CandidateCardLoader>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h4 class="empty-message" v-if="isEmpty">{{ $t("No applicants found") }}</h4>
                        <div class="row row-cols-xl-5 has-col-gap" v-else>
                            <div class="col" v-for="candidate in candidates">
                                <candidate-card :applicant="candidate"></candidate-card>
                            </div>
                        </div>
                        <pagination v-if="!isEmpty" :pagination="paginationData" @paginate="paginateTo"></pagination>
                    </template>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../layouts/DashboardLayout";
import CandidateCard from "../../components/candidate/CandidateCard";
import CandidateSearch from "../../components/candidate/CandidateSearch";
import Pagination from "../../components/pagination/Pagination";
import client from "../../app/api/Client";
import Multiselect from 'vue-multiselect';
import CandidateCardLoader from "../../components/_loaders/CandidateCardLoader";
import {debounce} from 'lodash'
import {DEFAULT_PIPELINES_ID} from "../../extra/constants";

export default {
    components: {
        DashboardLayout,
        CandidateSearch,
        CandidateCard,
        Pagination,
        Multiselect,
        CandidateCardLoader,
    },
    data() {
        return {
            isContentLoading: true,
            jobs: [],
            candidates: [],
            isLoading: true,
            paginationData: {},
            ratings: [
                {id: 5},
                {id: 4},
                {id: 3},
                {id: 2},
                {id: 1},
                {id: 0}
            ],
            page: 1,
            filter: {
                job: null,
                candidateName: "",
                status: "",
                rating: ""
            },
            defaultStatus: [
                {
                    id: null,
                    title: this.$t('All'),
                },
                {
                    id: DEFAULT_PIPELINES_ID.APPLIED,
                    title: this.$t('Applied'),
                },
                {
                    id: DEFAULT_PIPELINES_ID.SELECTED,
                    title: this.$t('Selected'),
                },
                {
                    id: DEFAULT_PIPELINES_ID.REJECT,
                    title: this.$t('Rejected'),
                }
            ],
            showCandidateFilter: false
        }
    },
    computed: {

        isEmpty() {
            return this.candidates.length === 0;
        },
        jobApplyStatus() {
            let defaultStatus = _.cloneDeep(this.defaultStatus);

            if (!_.isNull(this.filter.job)) {
                let pipelines = _.cloneDeep(this.filter.job.pipelines);
                pipelines = pipelines.map(e => {
                    return {
                        id: e.id,
                        title: this.$t(e.name)
                    }
                });

                return [{
                    id: null,
                    title: this.$t('All'),
                }, ...pipelines];
            }

            return defaultStatus;
        }
    },
    methods: {
        jobSelected() {
            this.filter.status = null;
        },

        paginateTo(page) {
            if (typeof page === 'undefined') page = 1;
            this.page = page;
            this.addQueryUrl();
            this.getCandidates();
        },

        toggleCandidateFilter() {
            this.showCandidateFilter = !this.showCandidateFilter;
        },

        getJobs() {
            return client().get('/company/jobs').then(({data: {data}}) => {
                this.jobs = data;
                return data;
            });
        },

        resetFilter() {
            this.page = 1;
            this.filter.candidateName = "";
            this.filter.job = null;
            this.filter.status = null;
            this.filter.rating = null;
            this.addQueryUrl();
            this.getCandidates();
        },

        async getCandidates() {
            this.isContentLoading = true;

            let params = {
                ...(this.page > 1) && {page: this.page},
                ...(!_.isEmpty(this.filter.job)) && {job_id: this.filter.job.id},
                ...(!_.isEmpty(this.filter.candidateName)) && {candidate_name: this.filter.candidateName},
                ...(!_.isEmpty(this.filter.status)) && {status: this.filter.status.id},
                ...(!_.isEmpty(this.filter.rating)) && {rating: this.filter.rating.id}
            };

            try {
                let {data: {data}} = await client().get('/company/candidates', {
                    "params": params
                });

                this.paginationData = data;

                if (data.data) {
                    this.candidates = data.data;
                }
                this.isContentLoading = false;
                return data;
            } catch (e) {
                this.isContentLoading = false;
            }
        },

        filterCandidates: debounce(function () {
            this.page = 1;
            this.addQueryUrl();
            this.getCandidates();
        }, 1500),

        addQueryUrl() {
            let params = {
                ...(!_.isEmpty(this.filter.job)) && {job: this.filter.job.id},
                ...(!_.isEmpty(this.filter.candidateName)) && {candidate: this.filter.candidateName},
                ...(!_.isEmpty(this.filter.status)) && {status: this.filter.status.id},
                ...(!_.isEmpty(this.filter.rating)) && {rating: this.filter.rating.id},
                ...(this.page > 1) && {page: this.page}
            };
            this.$router.push({name: 'company.candidates', query: params});
        },

        loadFilterFromRouteParams(res) {
            let job = this.$route.query.job || null;
            let status = !_.isEmpty(this.$route.query.status) || this.$route.query.status === 0 ? this.$route.query.status : null;
            let rating = this.$route.query.rating || null;
            let candidate = this.$route.query.candidate;
            this.page = this.$route.query.page;

            if (!_.isNull(job)) {
                this.filter.job = this.jobs.find(j => j.id == job) || null;
            }

            if (!_.isNull(status)) {
                this.filter.status = this.jobApplyStatus.find(s => s.id == status) || null;
            }

            if (!_.isNull(rating)) {
                this.filter.rating = this.ratings.find(s => s.id == status) || null;
            }

            if (!_.isNull(candidate)) {
                this.filter.candidateName = candidate;
            }
        }
    },
    async mounted() {
        this.getJobs().then((res) => {
            this.loadFilterFromRouteParams(res);
            this.getCandidates();
        });
        await this.$refs.candidate_name.focus();
    }
}
</script>


<style>
    span.multiselect__single {
        background: transparent;
    }
</style>

<style scoped>
    .filter__toggler {
        display: none;
    }
    @media all and (max-width: 767px) {
        .filter__toggler {
            display: inline-block;
            height: 35px;
            width: 35px;
            background: #fff;
            border-radius: 5px;
            line-height: 35px;
            text-align: center;
        }
        .candidates-filter {
            position: relative;
        }
        .user-list .filter__options {
            display: none !important;
            background: #fff;
            border-radius: 10px;
            padding: 20px;
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            width: 100%;
            z-index: 99;
            box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, .1);
        }
        .user-list .filter__options input {
            background: #f5f7fd;
            border: 1px solid rgba(89, 125, 252, .3);
        }
        .user-list .filter__options.show {
            display: block !important;
        }
    }
    .form-group {
        margin-right: 15px;
        width: 15%;
    }
    .form-group:first-child, .form-group:nth-child(2) {
        width: 25%;
    }
    .form-filter.candidates-filter>* {
        margin-bottom: 0;
    }
    .form-filter.candidates-filter {
        margin-bottom: 10px;
    }
    @media (max-width: 1200px) {
        .form-group {
            width: 16%;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .form-group {
            width: 25%;
        }

        .form-group:first-child, .form-group:nth-child(2) {
            width: 45%;
        }

        .form-filter.candidates-filter .button {
            width: auto !important;
        }
    }

    @media (max-width: 767px) {
        .form-group, .form-group:first-child, .form-group:nth-child(2) {
            width: 100%;
            margin-right: 0;
        }
    }
</style>

