<template>
    <form action="" class="form-filter">
        <div class="search-bar"><input type="text" :placeholder="$t('search candidates name')"/></div>
        <div class="select-option">
            <select>
                <option value="0">{{ $t("Select Job")}}</option>
                <option value="1">{{ $t("frontend developer")}}</option>
                <option value="2">{{ $t("content writer")}}</option>
                <option value="3">{{ $t("hr executive")}}</option>
                <option value="4">{{ $t("wordpress developer")}}</option>
            </select>
        </div>
        <div class="select-option">
            <select>
                <option value="0">{{ $t("Select Rating")}}</option>
                <option value="1">5</option>
                <option value="2">4.5</option>
                <option value="3">4</option>
                <option value="4">3.5</option>
                <option value="5">3</option>
                <option value="6">2</option>
                <option value="7">1</option>
                <option value="8">0</option>
            </select>
        </div>
        <button class="button primary-button">{{ $t("Filter")}}</button>
    </form>
</template>
<script>
    export default {

    }
</script>
